import React, { useState } from "react";
import "./index.scss";
import Layout from "../layouts";
import SEO from "../components/seo";
import Button from "../components/button";
import { navigate } from "gatsby";
import Modal from "../components/modal";

const IndexPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <SEO
        title="Home"
        lang="en"
        meta={[
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1.0",
          },
        ]}
      />
      <section className="main-container">
        <h2 className="index-header">Solving the age old question...</h2>
        <div className="where-to-eat">
          <span className="where">Where </span>
          <br />
          <span className="to">
            to <span className="eat">eat?</span>
          </span>
        </div>
        <Button
          text="Create a group"
          style={{ width: "100%", maxWidth: "20rem", marginTop: "3rem" }}
          onClick={() => navigate("/create-group")}
          ariaLabel="Create a group"
        />
        <Button
          text="Join friends"
          style={{ width: "100%", maxWidth: "20rem", marginTop: "3rem" }}
          ariaLabel="Join friends"
          onClick={() => setIsModalVisible(true)}
        />
        <Modal
          visible={isModalVisible}
          onBackdropClick={() => setIsModalVisible(false)}
        />
      </section>
    </>
  );
};

export default IndexPage;
