import { navigate } from "gatsby";
import React, { useContext, useRef, useState } from "react";
import { Context } from "../context/store";
import Button from "./button";

import "./modal.scss";

interface ModalProps {
  visible: boolean;
  onBackdropClick?: () => void;
}

const Modal = ({ visible, onBackdropClick }: ModalProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { state } = useContext(Context);
  const [token, setToken] = useState("");
  const [invalid, setInvalid] = useState(false);

  const handleTouch = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (contentRef && !contentRef.current?.contains(e.target as Node)) {
      setInvalid(false);
      onBackdropClick?.();
    }
  };

  const handleSubmit = () => {
    if (token.length === 6) {
      navigate(`/session/${token}`);
    } else {
      setInvalid(true);
    }
  };

  return (
    <div
      className="modal"
      style={{
        pointerEvents: visible ? "auto" : "none",
        opacity: visible ? 1 : 0,
      }}
      onClick={e => handleTouch(e)}
    >
      <div
        className="modal-content"
        ref={contentRef}
        style={{
          backgroundColor:
            state.theme === "light" ? "white" : "hsl(209, 18%, 30%)",
        }}
      >
        <div className="modal-content-container">
          <label htmlFor="token">Enter Room Code:</label>
          <input
            className="token-input"
            id="token"
            name="token"
            type="number"
            onChange={event => setToken(event.target.value)}
            style={{
              backgroundColor:
                state.theme === "light" ? "white" : "hsl(209, 18%, 30%)",
              border:
                state.theme === "light"
                  ? "2px solid #CCC"
                  : "2px solid hsl(211, 10%, 53%)",
              color: state.theme === "light" ? "black" : "white",
            }}
            autoComplete="off"
          ></input>
          <Button
            text="Join"
            ariaLabel="Join"
            style={{ padding: ".5rem 0" }}
            onClick={() => handleSubmit()}
          />
          <div
            className="token-error"
            style={{
              maxHeight: invalid ? "18px" : "0px",
              overflow: invalid ? "visible" : "hidden",
              opacity: invalid ? 1 : 0,
              paddingTop: invalid ? ".5rem" : "0",
              paddingBottom: invalid ? ".5rem" : "0",
            }}
          >
            <p>Invalid room code</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
